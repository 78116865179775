@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
  font-family: 'Abhaya Libre', serif;
  background-color: white;
}

.section {
  padding: 5%;
}

.title {
  font-size: xx-large;
  color: #544332;
}

.sub-title {
  font-size: large;
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: center;
}

.description {
  width: 70%;
}

.text-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.button-color {
  color: #8B8B8B;
}

.projects {
  display: flex;
  align-items: center; /* Align items vertically in the center */
  justify-content: space-between; /* Optional: Spacing between image and text */
}

.preview {
  flex-shrink: 0; /* Prevents the image from shrinking */
  margin-right: 20px; /* Space between the image and the text div */
  max-width: 150px; /* Adjust as needed */
  max-height: 150px; /* Maintain aspect ratio */
}

.outer-projects {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 600px) { /* Example breakpoint for mobile */
  .projects {
    flex-direction: column; /* Stack image and text vertically on smaller screens */
  }

  .preview {
    margin-right: 0;
    margin-bottom: 20px; /* Space between image and text on small screens */
    width: 100%; /* Full width on smaller screens */
  }
}


.typewriter {
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: .15em solid #544332; /* The typwriter cursor */
  white-space: wrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: .15em; /* Adjust as needed */
  animation: 
    typing 3.5s steps(40, end),
    blink-caret .75s step-end infinite;
}

.typewriter-second {
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: .15em solid #544332; /* The typwriter cursor */
  white-space: wrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: .15em; /* Adjust as needed */
  animation: 
    typing 3.5s steps(40, end) 3.5s, /* Delay the start by 3.5s */
    blink-caret .75s step-end infinite 3.5s; /* Delay the start of blinking caret as well */
}

/* The typing effect */
@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: #544332; }
}
